// / <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular";

import { AppModule } from './app/app.module';

// $entry to have error logging
Sentry.init({
  dsn: "https://42a2a96d14ff35e919a47f0b0cf553b6@o4508256938098688.ingest.de.sentry.io/4508256954875985",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      networkDetailAllowUrls: [
        window.location.origin,
        "https://www.casinocontroller.com",
        "https://demo.casinocontroller.com",
        "https://gp.0x6e.com"
      ],
      networkRequestHeaders: ["Cache-Control"],
      networkResponseHeaders: ["Referrer-Policy"],
      networkCaptureBodies: true
    }),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
